import react, { useEffect, useState } from 'react'
import {PrivyProvider} from '@privy-io/react-auth';
import {RouterProvider, useLocation} from 'react-router-dom';
import {ThemeProvider} from "styled-components";
import router from '../../router'
import { Sidebar } from '../../elements/sidebar';
import './style.css'
import MobileScreen from '../mobileScreen';

const prodId = 'clw85551r0351g4avkyfj13x8'
const localId = 'clvfi2bul00bbg4oekhoy2lyr'

const theme = {
    color: {
        primary: "#7C93F7"
    }
}

export const Application: react.FC = () => {
    const [suffix, setSuffix] = useState('');
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        var splittedHref = window.location.href.split('/')
        setSuffix(splittedHref[splittedHref.length-1]);
        window.onhashchange = () => {
            setUpdate(!update);
        }
    }, [update]);
    const isMobile = window.innerWidth <= 768;

    return <PrivyProvider
        appId={prodId}
    >
            <div className='app__container'>
                {isMobile ? (
                    <MobileScreen />
                ) : (
                    <>
                        <RouterProvider router={router}/>
                    </>
                )}
            </div>
    </PrivyProvider>
}